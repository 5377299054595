<template>
  <div id="user-dashboard" style="width: 108%;">
    <h3 class="settings-title md-xsmall-hide">{{ $t('sidebar.settings') }}</h3>
    <div class="md-layout setting-container md-small-size-100">
      <sidebar class="md-layout-item md-size-15" style="transform: translateX(-50px);"/>
      <div class="main-content md-layout-item md-size-85 md-small-size-100 dashboard-content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/common/user/Sidebar.vue';


export default {
  name: 'UserDashboard',
  components: {
    Sidebar,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/user-dashboard.scss';
</style>
